<template>
  <div class="index">
    <!-- header -->
    <div class="top">
      <div class="topH clearfloat">
        <div class="fl topLogo">
          <a href="/"><img alt="Vue logo" src="../assets/logo.png" /></a>
        </div>
        <div class="fr">
          <a v-show="isIndex" class="topPC" href="https://www.ghatg.com/">PC版</a>
          <a v-show="!isIndex" class="topBack" href="https://www.ghatg.com/"><img src="../assets/img/menu.png" alt=""></a>
        </div>
      </div>
      <div class="nav">
        <el-row>
          <el-col class="navInner" :class="{'active': active == item.id}" :span="4" v-for="(item, index) in listNav" :key="index">
            <a :href="item.url">{{item.name}}</a>
          </el-col>
          <!-- <el-col :span="4">
            <a href="/list">公路</a>
          </el-col>
          <el-col :span="4">
            <a href="/list">航空</a>
          </el-col>
          <el-col :span="4">
            <a href="/list">旅游</a>
          </el-col>
          <el-col :span="4">
            <a href="/list">金融</a>
          </el-col>
          <el-col :span="4">
            <a href="/list">保险</a>
          </el-col> -->
        </el-row>
      </div>
    </div>

    <router-view />

    <!-- footer -->
    <div class="footer">
      <div class="footerPic">
        <img src="../assets/img/2wm01.png" alt="">
        <p>甘肃省公航旅微信公众平台</p>
      </div>
      <div class="footerBacktop" @click="toTop">
        返回顶部
      </div>
      <div class="footerType">
        <el-row>
          <el-col :span="12"><a href="https://www.ghatg.com/">PC版</a></el-col>
          <el-col :span="12"><a class="footerTypeMobil" href="/">手机版</a></el-col>
        </el-row>
      </div>
      <div class="footerDer">
        <p>甘肃省公路航空旅游投资集团有限公司 版权所有</p>
        <p>联系地址：甘肃省兰州市城关区南昌路1716号</p>
        <p>联系电话：0931-8864009、0931-8860770</p>
        <p>备案序号：<a href="http://www.miibeian.gov.cn/">陇ICP备11000302号-1</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  data(){
    return {
      active: -1,
      isIndex: true,
      listNav: [
        {
          name: '概况',
          url: '/brief',
          id: 0
        },
        {
          name: '公路',
          url: '/list?id=14',
          id: 14
        },
        {
          name: '航空',
          url: '/list?id=15',
          id: 15
        },
        {
          name: '旅游',
          url: '/list?id=16',
          id: 16
        },
        {
          name: '金融',
          url: '/brief?id=17',
          id: 17
        },
        {
          name: '保险',
          url: '/list?id=18',
          id: 18
        },
      ]
    }
  },
  mounted(){
    var txt = this.$route.name
    console.log('txt :>> ', txt);
    this.active = this.$route.query.id || 0
    if(txt == 'Home'){
      this.active = -1
    }
  },
  methods: {
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      this.timer = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
            clearInterval(this.timer);
        }
      }, 10);
    }
  }
}
</script>
<style>
  .top {
    position: fixed;
    width: 100%;
    max-width: 750px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 666;
  }
  .topH {
    height: 1rem;
    background: #286dbc;
  }
  .topLogo img {
    width: 5.4rem;
    margin: 0.22rem 0 0 0.2rem;
  }
  .topPC {
    display: inline-block;
    width: 0.94rem;
    height: 0.38rem;
    line-height: 0.38rem;
    text-align: center;
    font-size: 0.24rem;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
    margin: 0.3rem 0.2rem 0 0;
  }
  .topBack {
    display: inline-block;
    width: 0.48rem;
    margin: 0.26rem 0.2rem 0 0;
  }
  .topBack img {
    width: 100%;
  }
  .nav {
    padding: 0.1rem 0.1rem;
    background: #fff;
    border-bottom: 1px solid #eee;
    box-shadow: 0 2px 0.1rem 0 #e9e9e9;
  }
  .nav a {
    display: block;
    height: 0.6rem;
    line-height: 0.6rem;
    text-align: center;
    font-size: 0.34rem;
  }
  .footer {
    text-align: center;
    padding: 0.4rem 0 0.25rem;
  }
  .footerPic img {
    width: 1.5rem;
  }
  .footerPic p {
    padding: 10px;
    font-size: .26rem;
    line-height: 0.3rem;
  }
  .footerBacktop {
    width: 2.72rem;
    height: 0.62rem;
    margin: 0.4rem auto;
    text-align: center;
    background-color: #fcfcfc;
    border: 1px solid #c8c6c6;
    font-size: 0.28rem;
    line-height: 0.62rem;
  }
  .footerType {
    width: 3.4rem;
    margin: 0.66rem auto 0.16rem;
    font-size: 0.28rem;
  }
  .footerType a {
    color: #999;
    line-height: 0.4rem;
  }
  .footerType a.footerTypeMobil {
    color: #e95a5a;
  }
  .footerDer p,.footerDer a {
    color: #999;
    line-height: 0.46rem;
  }
  .navInner.active a {
    color: #fff;
    background-color: #286dbc;
  }
</style>