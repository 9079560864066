<template>
  <div class="member bgFFF memberMM">
    <div class="memberWrap">
      <div class="memberHd">集团高管</div>
      <div class="memberBox">
        <div class="memberGrop clearfloat" v-for="(item, index) in list01" :key="index">
          <div class="fl">
            <img :src="item.img" alt="">
          </div>
          <div class="inner">
            <div class="memberTit">
              {{item.title}}
            </div>
            <div class="memberCont" v-html="item.content">
            </div>
          </div>
        </div>
      </div>

      <div class="memberHd" style="padding-top: 0.25rem;">高管助理</div>
      <div class="memberBox">
        <div class="memberGrop clearfloat" v-for="(item, index) in list02" :key="index">
          <div class="fl">
            <img :src="item.img" alt="">
          </div>
          <div class="inner">
            <div class="memberTit">
              {{item.title}}
            </div>
            <div class="memberCont" v-html="item.content"></div>
          </div>
        </div>
      </div>


    </div>
    
  </div>
</template>

<script>
export default {
  nama: "Member",
  data(){
    return {
      list01: [],
      list02: []
    }
  },
  mounted(){
    this.getListJtgg()
    this.getListJtggSub()
  },
  methods: {
    async getListJtgg(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getAllNewsList', {group_id: 162});
      _this.list01 = data.data;
    },
    async getListJtggSub(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getAllNewsList', {group_id: 164});
      _this.list02 = data.data;
    },
  }
}
</script>
<style>
.memberHd {
  font-size: 0.4rem;
  font-weight: bold;
  margin-bottom: 0.2rem;
}
.memberWrap {
  padding: 0 0.2rem;
}
.memberGrop {
  padding: 0.15rem 0;
  margin-bottom: 0.1rem;
  border-bottom: 1px dashed #ccc;
}
.memberGrop .fl {
  width: 2rem;
  height: 2.5rem;
}
.memberGrop .fl img {
  width: 100%;
  height: 100%;
}
.memberGrop .inner {
  margin-left: 2.2rem;
}
.memberTit {
  font-size: 0.3rem;
  line-height: 0.4rem;
  margin-bottom: 0.1rem;
  font-weight: bold;
}
.memberCont {
  line-height: 0.38rem;
}
</style>