<template>
  <div class="home">
    <el-carousel class="homePic" height="4.6rem" indicator-position="none">
      <el-carousel-item v-for="item in sec01LImg" :key="item.id">
        <div style="height: 100%;">
          <a :href="`/detail?id=${item.id}`">
            <img :src="item.img" alt="">
            <p class="single-line">{{item.title}}</p>
          </a>
        </div>
        <h3 class="small">{{ item }}</h3>
      </el-carousel-item>
    </el-carousel>

    <div class="homeImpor">
      <div class="homeImporW">
        <div class="homeImporL">滚动</div>
        <div class="homeImporGrop">
          <el-carousel height="0.9rem" direction="vertical" :interval="2500" indicator-position="none">
              <el-carousel-item v-for="item in newsTop" :key="item.id">
                <p class="single-line">
                  <router-link class="intro-btn" :to="`/detail?id=${item.id}`">{{ item.title }}</router-link>
                </p>
              </el-carousel-item>
            </el-carousel>
        </div>
      </div>
    </div>

    <div class="homeNews">
      <ul class="homeNewsList">
        <li :class="{'on' : (!item.img || item.img == '')}" v-for="(item,index) in list" :key="index">
          <a class="clearfloat" :href="`/detail?id=${item.id}`">
            <div class="fl homeNewsPic">
              <img :src="item.img" alt="">
            </div>
            <div class="inner homeNewsInner">
              <h3 class="multi-line1">{{item.title}}</h3>
              <p>日期：{{item.update_time.slice(0,10)}}</p>
            </div>
          </a>
        </li>
      </ul>
      
    </div>
    
    <div class="homeBottom">
      <a href="/list?id=7">更多要闻</a>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    // HelloWorld,
  },
  data(){
    return {
      sec01LImg: [],
      newsTop: [],
      list: [],
    }
  },
  mounted(){
    var _this = this

    _this.getImgNews()
    _this.getImprotNews()
    _this.getListNews()
  },
  methods: {
    // 焦点图
    async getImgNews(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getImgNews');
      _this.sec01LImg = data.data.list.slice(0,5);
    },
    // 滚动
    async getImprotNews(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getImportNews');
      _this.newsTop = data.data.list;
    },
    // list
    async getListNews(){
      var _this = this;
      var { data } = await _this.$http.post(
        _this.apiUrl + '/news/getNewsList',
         {page: 1, group_id: 7});
      _this.list = data.data.list;
    },
  }
};
</script>

<style>
  
</style>
