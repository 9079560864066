<template>
  <div class="company memberMM">
    <div class="companyHd">
      <el-row :gutter="18">
        <el-col :span="8" v-for="(item, index) in list" :key="index">
          <!-- <router-link :class="{'on' : active == index}" :to="item.url" @click="haddleBtn(index)">{{item.name}}</router-link> -->
          <a :class="{'on' : active == item.url}" :href="item.url">{{item.name}}</a>
        </el-col>
      </el-row>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  nama: "Company",
  data(){
    return {
      active: '/company',
      list: [
        {
          name: '集团概况',
          url: '/brief'
        },
        {
          name: '领导成员',
          url: '/member'
        },
        {
          name: '大事记',
          url: '/work'
        },
        {
          name: '公司荣誉',
          url: '/honor'
        },
        {
          name: '联系我们',
          url: '/contact'
        },
      ]
    }
  },
  mounted(){
    var _this = this
    console.log('_this.$route.name :>> ', _this.$route.name);
    _this.active = '/' + _this.$route.name
  },
  methods: {
    haddleBtn(val){
      var _this = this

      _this.active = val
    }
  }
}
</script>