import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/index.vue";
import Home from "../views/Home.vue";
import Company from "../views/company/company.vue";
import Brief from "../views/company/brief.vue";
import Member from "../views/company/member.vue";
import Work from "../views/company/work.vue";
import Honor from "../views/company/honor.vue";
import Contact from "../views/company/contact.vue";
import List from "../views/list.vue";
import Detail from "../views/detail.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: '/list',
        name: 'List',
        component: List
      },
      {
        path: '/detail',
        name: 'detail',
        component: Detail
      },
      {
        path: '/company',
        name: 'company',
        component: Company,
        children: [
          {
            path: '/',
            name: 'brief',
            component: Brief
          },
          {
            path: '/brief',
            name: 'brief',
            component: Brief
          },
          {
            path: '/member',
            name: 'member',
            component: Member
          },
          {
            path: '/work',
            name: 'work',
            component: Work
          },
          {
            path: '/honor',
            name: 'honor',
            component: Honor
          },
          {
            path: '/contact',
            name: 'contact',
            component: Contact
          }
        ]
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
