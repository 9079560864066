<template>
  <div class="brief bgFFF memberMM">
    <!-- <div class="detailHd">
      <div class="detailHdTit">
        {{cont.title}}
      </div>
      <div class="detailHdCont">
        <span>浏览次数：<label>{{cont.view_number}}</label></span>
        <span>发布时间：<label>{{ cont.update_time.slice(0,10) }}</label></span>
      </div>
    </div> -->
    <div class="detailWrap" v-html="cont.content">

    </div>
  </div>
</template>

<script>
export default {
  nama: "Brief",
  data(){
    return {
      cont: {
        title: '',
        view_number: '',
        update_time: '',
        content: ''
      }
    }
  },
  mounted(){
    this.getData()
  },
  methods: {
    async getData() {
      var _this = this;
      var id = 6;
      var { data } = await _this.$http.post( _this.apiUrl + "/news/getNewsDetail", { id: id });
      _this.cont = data.data;
    },
  }
}
</script>