<template>
  <div class="work bgFFF memberMM">
    <ul class="homeNewsList listWrap">
      <li v-for="(item,index) in sec01LImg" :key="index" :class="{'listImg' : !item.img}">
        <a class="clearfloat" :href="`/detail?id=${item.id}`">
          <div class="fl homeNewsPic">
            <img :src="item.img" alt="">
          </div>
          <div class="inner homeNewsInner">
            <h3 class="multi-line1">{{item.title}}</h3>
            <p>日期：{{item.update_time.slice(0,10)}}</p>
          </div>
        </a>
      </li>
    </ul>

    <div class="listPage">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="pageList"
        small
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  nama: "Work",
  data(){
    return {
      sec01LImg: [],
      total: 0,
      pageSize: 20,
      currentPage: 1,
    }
  },
  mounted(){
    this.getData()
  },
  methods: {
    pageList(index) {
      var _this = this;
      // console.log("index :>> ", index);
      _this.currentPage = index;
      _this.getData();
    },
    async getData() {
      var _this = this;
      // var group_id = _this.$route.query.id;
      var { data } = await _this.$http.post(
        _this.apiUrl + "/news/getNewsList",
        {
          page: _this.currentPage,
          group_id: 3,
        }
      );
      _this.total = data.data.total;
      _this.sec01LImg = data.data.list;
    },
  }
}
</script>